import Photo1 from '../../assets/img/Teams/PaIrwan.png';
import Photo2 from '../../assets/img/Teams/BuRita.png';
import Photo3 from '../../assets/img/Teams/Hilmi.png';
import Photo4 from '../../assets/img/Teams/Raihan.png';

const photoObjects = [
    {id: 1, src:Photo1, alt:"Pa Irwan", name:"Irwan Gunawan, M.Pd.", position:"Founder & Director"},
    {id: 2, src:Photo2, alt:"Bu Rita", name:"Dr. Rita Anggorowati, M.Pd.", position:"Co-Founder & Academic Director"},
    {id: 3, src:Photo3, alt:"Hilmi", name:"Hilmi Taofik D.", position:"Content & Social Media Specialist"},
    {id: 4, src:Photo4, alt:"Raihan", name:"Muhammad Raihan Satrio", position:"Web Design Specialist"}
];

export const Teams = () => {
    return (
        <div id="team">
            <div className="container">
                <div className="title-container">
                    <h2>Introducing</h2>
                    <h1>Our Teams</h1>
                </div>
                <div className="row">
                    {photoObjects.map((obj) => 
                        <div className="col-md-6 col-lg">
                            <img src={obj.src} alt={obj.alt}/>
                            <h1>{obj.name}</h1>
                            <h2>{obj.position}</h2>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}