import Logo from '../../assets/img/LogoICEI.svg';

export const Navbar = () => {
    return (
        <nav id="navbar" className="navbar navbar-expand-md navbar-dark bg-white">
            <div className="container">
                <button className="navbar-toggler flex-grow-sm-1 flex-grow-0 me-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbar5">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <a href="/" className="navbar-brand flex-grow-1">
                    <img src={Logo}/>
                </a>
                <div className="navbar-collapse collapse justify-content-center" id="navbar5">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="btn btn-primary custom" href="/">HOME</a>
                        </li>
                        <li className="nav-item">
                            <a className="btn btn-primary custom" href="#history">ABOUT US</a>
                        </li>
                        <li className="nav-item">
                            <a className="btn btn-primary custom" href="#activity">OUR ACTIVITIES</a>
                        </li>
                        <li className="nav-item">
                            <a className="btn btn-primary custom" href="#partner">OUR PARTNERS</a>
                        </li>
                        <li className="nav-item">
                            <a className="btn btn-primary custom" href="#client">OUR CLIENTS</a>
                        </li>
                    </ul>
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <a className="btn btn-primary main" href="https://wa.me/+6281324955045">CONTACT US</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}