import React, { createContext, useState, ReactElement } from 'react'

export interface Modal {
    show: boolean;
    activityDesc: string;
    activityTitle: string;
    setShow: (newShow: boolean) => void;
    setActivityDesc: (newActivityDesc: string) => void;
    setActivityTitle: (newActivityTitle: string) => void;
}

export interface ProviderProps { 
    children: ReactElement | ReactElement[];
}

export const ModalContext = createContext<Modal>({
    show: false,
    activityDesc: "",
    activityTitle: "",
    setShow: () => {},
    setActivityDesc: () => {},
    setActivityTitle: () => {}
});

export const ModalContextProvider = ( props: ProviderProps ) => {
    const [show, setShow] = useState(false);
    const [activityDesc, setActivityDesc] = useState('');
    const [activityTitle, setActivityTitle] = useState('');

    const value = { 
        show: show, 
        activityDesc: activityDesc, 
        activityTitle: activityTitle,
        setShow: (newShow: boolean) => {
            setShow(newShow);
        }, 
        setActivityDesc: (newActivityDesc: string) => {
            setActivityDesc(newActivityDesc);
        },
        setActivityTitle: (newActivityTitle: string) => {
            setActivityTitle(newActivityTitle);
        }
    };

    return (
        <ModalContext.Provider value={value}>{props.children}</ModalContext.Provider>
    )
}

