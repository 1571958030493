import Teaching from '../../assets/img/teaching.svg';
import Growth from '../../assets/img/icon-growth.svg';
import Leader from '../../assets/img/icon-leader.svg';

export const HistoryFeature = () => {
    return (
        <div id="history-feature">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-sm-6">
                        <img className="teaching-image" src={Teaching} alt="Illustration"/>
                    </div>
                    <div className="col-xs-12 col-sm-6">
                        <div className="card custom">
                            <div className="card-body">
                                <div className="row ctn">
                                    <div className="col-xs-12 col-sm-3" style={{ textAlign: 'center' }}>
                                        <img className="icon-card" src={Growth} alt="Nothing"/>
                                    </div>
                                    <div className="col-xs-12 col-sm-9">
                                        <h1 className="title-card">Growth of Creative Industries</h1>
                                        <p className="desc-card">Indonesia currently requires the growth of creative industries that will sustain the growth of the national economy</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card custom">
                            <div className="card-body">
                                <div className="row ctn">
                                    <div className="col-xs-12 col-sm-3" style={{ textAlign: 'center' }}>
                                        <img className="icon-card" src={Leader} alt="Nothing"/>
                                    </div>
                                    <div className="col-xs-12 col-sm-9">
                                        <h1 className="title-card">Future Leaders</h1>
                                        <p className="desc-card">ICEI sees the prospect of teens as young generation and the nation's future leaders; which are not merely require to be smart and intelligent but also expected to have high creativity skills and strong innovation</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}