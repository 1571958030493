import Monash from '../../assets/img/Partners/Monash.png';
import Andrew from '../../assets/img/Partners/Andrew.png';
import SelectEnglish from '../../assets/img/Partners/SelectEnglish.png';
import BMS from '../../assets/img/Partners/BMS.png';

export const Partner = () => {
    return (
        <div id="partner">
            <div className="container">
                <div className="title-container">
                    <h1>Our Partners</h1>
                </div>
                <div className="row row-cols-sm-1 row-cols-md-4">
                    <div className="col">
                        <img className="logo-partner" src={Monash} alt="Monash Logo Image" />
                    </div>
                    <div className="col">
                        <img className="logo-partner" src={Andrew} alt="Andrew Logo Image" />
                    </div>
                    <div className="col">
                        <img className="logo-partner" src={SelectEnglish} alt="Select English Logo Image" />
                    </div>
                    <div className="col">
                        <img className="logo-partner" src={BMS} alt="BMS Logo" />
                    </div>
                </div>
            </div>
        </div>
    );
}