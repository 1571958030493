import Typing from "react-typing-animation";

export const Header = () => {
    return(
        <div id="header">
            <div className="row">
                <div className="col-xs-1 col-sm-3"></div>
                <div className="col-xs-10 col-sm-6">
                    <div className="title-container">
                        <Typing speed={100}>
                            <h1 className="title">Education <span style={{ color: '#FFCC29'}}>Consultant</span></h1>
                        </Typing>
                        <h1 id="exception" className="title">Teacher Empowerment</h1>
                        <h1 className="title">Learning Problem</h1>
                        <h2 className="subtitle">Indonesia Creative Education Institute</h2>
                        <div className="button-container">
                            <a href="/" className="btn btn-primary custom">GETTING STARTED</a>
                        </div>
                    </div>
                </div>
                <div className="col-xs-1 col-sm-3"></div>
            </div>
        </div>
    );
}