import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';

export const Footer = () => {
    return (
        <footer id="footer" className="footer-clean">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-sm-4 col-md-3 item">
                        <h3>OUR ACTIVITIES</h3>
                        <ul>
                            <li><a href="#icei-tc">ICEI Training Course</a></li>
                            <li><a href="#pia">Parenting in Action</a></li>
                            <li><a href="#ec">Educational Consultant</a></li>
                            <li><a href="#et">Educational Therapy</a></li>
                            <li><a href="#ci">Creative Immersion</a></li>
                            <li><a href="#ycp">Youth Creative Program</a></li>
                        </ul>
                    </div>
                    <div className="col-sm-4 col-md-3 item">
                        <h3>ON THIS WEBSITE</h3>
                        <ul>
                            <li><a href="/">Home</a></li>
                            <li><a href="#history">About Us</a></li>
                            <li><a href="#activity">Our Activities</a></li>
                            <li><a href="#partner">Our Partners</a></li>
                            <li><a href="#client">Our Clients</a></li>
                        </ul>
                    </div>
                        <div className="col-lg-3 item social">
                            <a href="mailto:iceibdg@gmail.com"> 
                                <FontAwesomeIcon icon={faEnvelope} style={{ color: '#FFC700' }} /> 
                            </a>
                            <a href="https://www.instagram.com/iceibandung/">
                                <FontAwesomeIcon icon={faInstagram} style={{ color: '#FFC700' }} />
                            </a>
                        <p className="copyright">ICEI Copyright © 2022</p>
                    </div>
                </div>
            </div>
        </footer>
    );
}