import Medika from '../../assets/img/Clients/Medika.png';
import Erlangga from '../../assets/img/Clients/Erlangga.png';
import RumahYatim from '../../assets/img/Clients/RumahYatim.png';
import Mazaya from '../../assets/img/Clients/Mazaya.png';
import BIMS from '../../assets/img/Clients/BIMS.png';
import Alexandria from '../../assets/img/Clients/Alexandria.png';
import LimaGroup from '../../assets/img/Clients/LimaGroup.png';
import LimaVentura from '../../assets/img/Clients/LimaVentura.png';
import Assakinah from '../../assets/img/Clients/Assakinah.png';
import UKAW from '../../assets/img/Clients/UKAW.png';
import PJKR from '../../assets/img/Clients/PJKR.png';
import UNJANI from '../../assets/img/Clients/UNJANI.png';
import PPSDM from '../../assets/img/Clients/PPSDM.png';

export const Client = () => {
    return (
        <div id="client">
            <div className="container">
                <div className="title-container">
                    <h1>Our Clients</h1>
                </div>
                <div className="row row-cols-sm-1 row-cols-md-5">
                    <div className="col">
                        <img className="logo-client" src={Medika} alt="logo Medika"/>
                    </div>
                    <div className="col">
                        <img className="logo-client" src={Erlangga} alt="logo Erlangga"/>
                    </div>
                    <div className="col">
                        <img className="logo-client" src={RumahYatim} alt="logo Rumah Yatim"/>
                    </div>
                    <div className="col">
                        <img className="logo-client" src={Alexandria} alt="logo Alexandria"/>
                    </div>
                    <div className="col">
                        <img className="logo-client" src={Mazaya} alt="logo Mazaya"/>
                    </div>
                </div>
                <div className="row row-cols-sm-1 row-cols-md-5">
                    <div className="col">
                        <img className="logo-client" src={LimaGroup} alt="logo Medika"/>
                    </div>
                    <div className="col">
                        <img className="logo-client" src={LimaVentura} alt="logo Erlangga"/>
                    </div>
                    <div className="col">
                        <img className="logo-client" src={Assakinah} alt="logo Rumah Yatim"/>
                    </div>
                    <div className="col">
                        <img className="logo-client" src={UKAW} alt="logo Alexandria"/>
                    </div>
                    <div className="col">
                        <img className="logo-client" src={PJKR} alt="logo Mazaya"/>
                    </div>
                </div>
                <div className="row row-cols-sm-1 row-cols-md-3">
                    <div className="col">
                        <img className="logo-client" src={UNJANI} alt="logo UNJANI"/>
                    </div>
                    <div className="col">
                        <img className="logo-client" src={PPSDM} alt="logo PPSDM" style={{ width: '175px' }}/>
                    </div>
                    <div className="col">
                        <img className="logo-client" src={BIMS} alt="logo BIMS"/>
                    </div>
                </div>
            </div>
        </div>
    );
}