export const History = () => {
    return (
        <div id="history">
            <div className="container">
                <div className="row">
                    <h2 className="subtitle">Let's talk about history</h2>
                    <h1 className="title">How did we get started ?</h1>
                    <div id="desc" className="col-xs-12 col-sm-9">
                        <strong>(ICEI) Indonesia Creative Education Institute</strong> was established on August 2015 as an alternative movement that transpired in answering the condition of Indonesian education and economy, which requires a breakthrough in producing superior generation.
                    </div>
                </div>
            </div>
        </div>
    );
}