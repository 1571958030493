import { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { ModalContext } from "../../contexts/modal.context";

export const Detail = () => {
    const context = useContext(ModalContext);

    return (
        <div id="detail-modal">
            <Modal className="modal" show={context.show} onHide={() => {context.setShow(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>{context.activityTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="desc">{context.activityDesc}</Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={() => {context.setShow(false)}}>
                    Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}