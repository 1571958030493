import Course from '../../assets/img/Class.png';
import Consultant from '../../assets/img/Consultant.jpg';
import Creative from '../../assets/img/Creative.jpg';
import Parenting from '../../assets/img/Parenting.jpg';
import Therapy from '../../assets/img/Therapy.jpg';
import Youth from '../../assets/img/Youth.jpg';
import Click from '../../assets/img/Click.png';
import { ModalContext } from '../../contexts/modal.context';
import { useContext } from "react";

const activityObject = [
    {
        id: 1, 
        title: 'ICEI Training Course', 
        objectId: 'icei-tc',
        description: 'ICEI Training course is a special program for everyone who want to fast track the learning of becoming a better person. This program focuses on hands-on training of the practical application of the materials',
        src: Course
    },
    {
        id: 2, 
        title: 'Parenting in Action', 
        objectId: 'pia',
        description: 'Educating a newly couple and parents who enter parenthood with adequate information about parenting, playing and making craft with kids. Doing an Activity so-called “Parenting in Action” which is not only giving a new understanding about parenting but also do direct activity with kids. Building a network and community such as Creative Parents Community',
        src: Parenting
    },
    {
        id: 3, 
        title: 'Educational Consultant', 
        objectId: 'ec',
        description: 'Our Services provide complete oversight regarding the overall operational management structure of our clients school. This to include Complete oversight for operation and management of the school, assessing and monitoring the quality of the educational programming, overseeing accreditation, and planning for additional grade levels/programs as required; Monitoring school performance and oversight of Annual strategic goals and evaluation/assessment process; Teachers & Staff professional development; Policy manuals, including students, parents, and staff; Parent services; Communication and public relations as well as school improvement.',
        src: Consultant
    },
    {
        id: 4, 
        title: 'Educational Therapy', 
        objectId: 'et',
        description: 'We believe that every child is unique and has their own potential, we just need to help them to discover and unleash it from within. We understand that every child has its own challenges in the learning process, but it is not an obstacle to develop their social relationships potential and academic development if they were given the appropriate interventions and strategies to the uniqueness that they had.',
        src: Therapy
    },
    {
        id: 5, 
        title: 'Creative Immersion', 
        objectId: 'ci',
        description: 'This Program is aimed for students to start from primary to secondary level who are willing to see, and experiencing a new learning environment and atmosphere in other countries At the moment, we are having a collaboration and doing cooperation with two institutions namely; Monash College in Australia and St. Andrews College in Cambridge, UK',
        src: Creative
    },
    {
        id: 6, 
        title: 'Youth Creative Program', 
        objectId: 'ycp',
        description: 'Youth Creative Empowerment Program is a program designed as our social activity with the aims to provide opportunities to the youth to further explore their life skills. This program is expected to bring out their creativity, leadership skills and the ability to express themselves through enormous activities. It helps them to unleash their self-worth and potentials; which will lead them to discover their true passion in life.',
        src: Youth
    }
];

export const Activity = () => {
    const context = useContext(ModalContext);
    return (
        <div id="activity">
            <div className="container">
                <div className="title-container">
                    <h2>Let's Recap</h2>
                    <h1>Our Activities</h1>
                </div>
                <div className="row">
                    {activityObject.map((obj) =>
                        <div className="col-12 col-sm-6 col-md-4">
                            <div id={obj.objectId} className="card">
                                <img className="card-img-top" src={obj.src} alt="Card image cap" style={{ height: '175px', objectFit: 'cover' }}/>
                                <div className="card-body">
                                    <h5 className="card-title">{obj.title}</h5>
                                    <a href={'#' + obj.objectId}
                                        onClick={() => {
                                            context.setShow(true); 
                                            context.setActivityDesc(obj.description)
                                            context.setActivityTitle(obj.title);
                                        }}
                                    >
                                        <div className="row">
                                            <div className="col-1">
                                                <img className="icon-card" src={Click} alt="Click" width={20}/>
                                            </div>
                                            <div className="col-11">
                                                <p>Learn More</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}