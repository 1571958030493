import { Activity } from "../components/Beranda/Activity";
import { Client } from "../components/Beranda/Client";
import { Footer } from "../components/Beranda/Footer";
import { Header } from "../components/Beranda/Header";
import { Detail } from "../components/Beranda/Detail";
import { History } from "../components/Beranda/History";
import { HistoryFeature } from "../components/Beranda/HistoryFeature";
import { Navbar } from "../components/Beranda/Navbar";
import { Partner } from "../components/Beranda/Partner";
import { Teams } from "../components/Beranda/Teams";
import { ModalContextProvider } from "../contexts/modal.context";

export const Beranda = () => {
    return (
        <>
            <Navbar/>
            <Header/>
            <History/>
            <HistoryFeature/>
            <Teams/>
            <ModalContextProvider>
                <Activity/>
                <Detail/>
            </ModalContextProvider>
            <Partner/>
            <Client/>
            <Footer/>
        </>
    );
}